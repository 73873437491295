<template>
  <div>
    <ValidationsNationalesComponent
      :total-national="selected.reduce((acc, val) => acc + val.montant_total, 0)"
      :enable-valider="Array.isArray(selected) && selected.length > 0"
      :enable-export-statistiques="true"
      :enable-export-sage="true"
      :enable-export-adherent-et-abonnees="true"
      @send="sendValidation()"
      @export-statistiques="exportStatistiques()"
      @export-sage="exportSAGE()"
      @export-adherent-et-abonnees="exportAdherentEtAbonnees()"
    />

    <ListValidationsNationalesComponent 
      v-model="selected"
    />
    
    <Downloader
      :downloading-file="downloadingFile"
      :label="downloadingFileLabel"
      @downloadSuccess="onDownloadSuccess()"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Downloader from '../../components/partials/Downloader';
import ValidationsNationalesComponent from '../../components/workflow/ValidationsNationalesComponent';
import ListValidationsNationalesComponent from '../../components/workflow/ListValidationsNationalesComponent';

export default {
  name: 'ValidationsNationalesContainer',
  components: {
    ValidationsNationalesComponent,
    ListValidationsNationalesComponent,
    Downloader,
  },
  data() {
    return {
      downloadingFile: null,
      downloadingFileLabel: null,
      selected: [],
    };
  },
  methods: {
    ...mapActions('validationsNationales', {
      send: 'sendValidationsNationales',
    }),
    sendValidation() {
      this.send({ lots: this.selected })
        .then(() => {
          this.downloadingFileLabel = 'recu de validation nationale';
          this.downloadingFile = {
            url: '/recucdpe',
            params: { listLots: this.selected.map(el => el.id) },
          };
          this.selected = [];
        });
    },
    onDownloadSuccess() {
      this.downloadingFile = null;
      this.downloadingFileLabel = null;
    },
    exportStatistiques() {
      this.downloadingFileLabel = 'export Statistiques';
      this.downloadingFile = {
        url: '/exportstatistique',
        params: { perimetreId: 1 },
      };
    },
    exportSAGE() {
      this.downloadingFileLabel = 'export SAGE';
      this.downloadingFile = {
        url: '/exportsage',
        params: { perimetreId: 1 },
      };
    },
    exportAdherentEtAbonnees() {
      let paramsUrl =  {
        type: 20, // 20 for IMPRESSION_FOYERS_VALIDES_PAR_DEPARTEMENT
        format: 3, // 3 for FORMAT_EXCEL
        cdpeId: 0,
        cpeId: 0,
        cpeCommuneId: 0,
        degEnseignId: 0,
        secteurGeogId: 0,
        responsabId: 0,
        delegationId: 0,
        etablissementId: 0,
        anneeScolaireCoId: 0,
        anneeScolairePrId: 0,
        anneeAdhesionId: 0,
        dateMin: '',
        dateMax: '',
        typeExtract: 0,
      };
      this.downloadingFileLabel = 'Adhérents et abonnées validés par département';
      this.downloadingFile = {
        url: '/impression',
        params: paramsUrl,
      };
    },
  },
};
</script>