<template>
  <div>
    <v-card>
      <div class="mb-5 pa-5">
        <v-card-title
          primary-title
          class="title"
        >
          Validations nationale
        </v-card-title>
        <div>
          <div class="mb-5">
            <v-row>
              <v-col
                cols="12"
                md="3"
              >
                Total national :
              </v-col>
              <v-col
                cols="12"
                md="3"
                class="text-right"
              >
                {{ totalNational | currency }}
              </v-col>
            </v-row>
          </div>
          <v-btn
            color="primary"
            class="ma-1"
            :disabled="!enableValider"
            @click.stop="showSendDialog = true"
          >
            Valider
          </v-btn>
          <v-btn
            color="primary"
            class="ma-1"
            :disabled="!enableExportStatistiques"
            @click="exportStatistiques"
          >
            Export Statistiques
          </v-btn>
          <v-btn
            color="primary"
            class="ma-1"
            :disabled="!enableExportSage"
            @click="exportSAGE"
          >
            Export SAGE
          </v-btn>
          <v-btn
            color="primary"
            class="ma-1"
            :disabled="!enableExportAdherentEtAbonnees"
            @click="exportAdherentEtAbonnees"
          >
            Export Adhérents et abonnées validés par département (format Excel) 
          </v-btn>
        </div>
      </div>
    </v-card>
    <ConfirmedModal
      v-if="showSendDialog"
      sentence="Êtes-vous sûr de vouloir valider ces lots ?"
      btn-action="Valider"
      @close="showSendDialog = false"
      @confirmed="validationNationale()"
    />
  </div>
</template>


<script>
import ConfirmedModal from '../reusableComponents/confirmedModal';

export default {
  name: 'ValidationsNationalesComponent',
  components: {
    ConfirmedModal,
  },
  props: {
    totalNational: { type: Number, default: 0 },
    enableValider: { type: Boolean, default: false },
    enableExportStatistiques: { type: Boolean, default: false },
    enableExportSage: { type: Boolean, default: false },
    enableExportAdherentEtAbonnees: { type: Boolean, default: false },
  },
  data() {
    return {
      showSendDialog: false,
    };
  },
  methods: {
    validationNationale() {
      this.showSendDialog = false;
      this.$emit('send');
    },
    exportStatistiques() {
      this.$emit('export-statistiques');
    },
    exportSAGE() {
      this.$emit('export-sage');
    },
    exportAdherentEtAbonnees() {
      this.$emit('export-adherent-et-abonnees');
    },
  },
};
</script>